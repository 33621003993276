import { getConstantsFor } from "../asyncAction";

export const ADD_TO_ORDER = "ADD_TO_ORDER";
export const ADD_ITEMS_TO_ORDER = "ADD_ITEMS_TO_ORDER";
export const SET_ORDER_DELIVERY_ADDRESS = "SET_ORDER_DELIVERY_ADDRESS";
export const SET_SERVING_OPTION = "SET_SERVING_OPTION";
export const SET_DELIVERY_TIP_PERCENTAGE = "SET_DELIVERY_TIP_PERCENTAGE";
export const SET_SERVING_OPTION_TIP_PERCENTAGE =
  "SET_SERVING_OPTION_TIP_PERCENTAGE";
export const SET_DELIVERY_TIP_CUSTOM_VALUE = "SET_DELIVERY_TIP_CUSTOM_VALUE";
export const SET_SERVING_OPTION_TIP_CUSTOM_VALUE =
  "SET_SERVING_OPTION_TIP_CUSTOM_VALUE";
export const RESET_ORDER_DELIVERY_ADDRESS = "RESET_ORDER_DELIVERY_ADDRESS";
export const SET_BRANCH_ID_AND_NEXT_AVAILABLE_TIME =
  "SET_BRANCH_ID_AND_NEXT_AVAILABLE_TIME";
export const SET_ORDER_TIP_AMOUNT = "SET_ORDER_TIP_AMOUNT";
export const SET_GIFT_CARDS_TO_REDEEM = "SET_GIFT_CARDS_TO_REDEEM";
export const RESET_ORDER_BRANCH_ID = "RESET_ORDER_BRANCH_ID";
export const START_ORDER_ITEM_EDIT = "START_ORDER_ITEM_EDIT";
export const CANCEL_ORDER_ITEM_EDIT = "CANCEL_ORDER_ITEM_EDIT";
export const APPLY_ORDER_ITEM_EDIT = "APPLY_ORDER_ITEM_EDIT";
export const START_NEW_ORDER = "START_NEW_ORDER";
export const REMOVE_FROM_ORDER = "REMOVE_FROM_ORDER";
export const REMOVE_ALL_FROM_ORDER = "REMOVE_ALL_FROM_ORDER";
export const APPLY_PARAMS_TO_ORDER = "APPLY_PARAMS_TO_ORDER";
export const SET_COUPONS_CHECKED = "SET_COUPONS_CHECKED";
export const UPDATE_ITEM_QUANTITY = "UPDATE_ITEM_QUANTITY";
export const START_REORDER = "START_REORDER";
export const SET_EXECUTE_PAYMENT = "SET_EXECUTE_PAYMENT";
export const ADD_PAYMENT_METHOD = getConstantsFor("ADD_PAYMENT_METHOD");
export const ORDER_APPROVE_CC_PAYMENT = getConstantsFor(
  "ORDER_APPROVE_CC_PAYMENT",
);
export const SAVE_ORDER = getConstantsFor("SAVE_ORDER");
export const ORDER_APPROVE_CASH_PAYMENT = getConstantsFor(
  "ORDER_APPROVE_CASH_PAYMENT",
);
export const ORDER_APPROVE_FREE_ORDER = getConstantsFor(
  "ORDER_APPROVE_FREE_ORDER",
);
export const RESET_REORDER = "RESET_REORDER";
export const RESET_EDIT_ITEM_MODE = "RESET_EDIT_ITEM_MODE";
export const RECHARGE_CARD = getConstantsFor("RECHARGE_CARD");
export const ADD_PAYMENT_METHOD_AND_CHECKOUT = getConstantsFor(
  "ADD_PAYMENT_METHOD_AND_CHECKOUT",
);
export const EDIT_ITEM_FROM_PAYMENT = "EDIT_ITEM_FROM_PAYMENT";
export const SET_CHARGE_CARD_DESELECTED = "SET_CHARGE_CARD_DESELECTED";
export const SET_ORDER_FIXED_REMARKS = "SET_ORDER_FIXED_REMARKS";
export const SET_FUTURE_SERVING_TIME = "SET_FUTURE_SERVING_TIME";
export const SET_PICKUP_TIME = "SET_PICKUP_TIME";
export const SET_USER_SELECTED_LOCATION_AND_SERVING_OPTION =
  "SET_USER_SELECTED_LOCATION_AND_SERVING_OPTION";
export const GET_ORDER_STATUS = getConstantsFor("GET_ORDER_STATUS");
export const ADD_PROMO_CODE = "ADD_PROMO_CODE";
export const REMOVE_PROMO_CODE = "REMOVE_PROMO_CODE";
export const SET_SEARCH_CODE = "SET_SEARCH_CODE";
export const SET_TABLE_CODE = "SET_TABLE_CODE";
export const RESET_TABLE_CODE = "RESET_TABLE_CODE";
export const SET_SERVING_OPTION_MANDATORY_FIELD =
  "SET_SERVING_OPTION_MANDATORY_FIELD";
export const PAY_CHECK = getConstantsFor("PAY_CHECK");
export const FIRE_ORDER_EVENT = "FIRE_ORDER_EVENT";
export const orderEventType = {
  addPaymentInfo: "addPaymentInfo",
  addToCart: "addToCart",
  removeFromCart: "removeFromCart",
  search: "search",
  selectItem: "selectItem",
  selectDeal: "selectDeal",
  beginCheckout: "beginCheckout",
};
export const OPEN_PAYMENT = getConstantsFor("OPEN_PAYMENT");

