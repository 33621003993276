import _ from "lodash";
import { extendMoment } from "moment-range";
import SERVICE from "../../api-services/constants";
import * as CONSTANTS from "../../utils/constants";
import {
    getAvailableFrom, getFirstAvailableDayAfterToday, getFutureOrderAvailability,
    getPickupTimes, getTimeFrameAfterTimeGiven, getTimeFrameIntersection, getTimeFrameIntersectWithTimeGiven
} from "../../utils/futureOrder";
import {
    getFirstComponentWithError,
    getSizeOptionPrice
} from "../../utils/menuItemOrderData";
import Moment from "../../utils/moment-timezone-with-data";
import {
    branchesAvailabilityReformatTimeFrames, reformatTimeFrames
} from "../../utils/timeFrameReformat";
import { getAppStyles } from "./app-styles";
import { getPromotions as getHomepagePromotions } from "./promotions";
const moment = extendMoment(Moment);

export const getStyles = getAppStyles;
export const getPromotions = getHomepagePromotions;

export const getPrivacyConsentOptions = (state) => {
  const {
    ageConsentText,
    loyaltyConsentText,
  } = _.get(state.user, 'privacyConsentDetails.data') || {};

  return [loyaltyConsentText, ageConsentText].filter(Boolean);
}

export const getMenuItemConfiguration = (state, props) => {
  const {
    menuItemsConfiguration,
    user: { userId = "common" },
  } = state;

  const {
    pageContext: { itemData },
  } = props;

  if (!menuItemsConfiguration[userId]) {
    return null;
  }

  const menuItemConfiguration = itemData.optionsOrderData
    ? menuItemsConfiguration[userId][itemData.id]
    : menuItemsConfiguration[userId][itemData.menuItemId];
  return menuItemConfiguration;
};

export const getDefaultPaymentMethodDetails = (state, props) => {
  const {
    pageContext: {
      business: { paymentTypeDetails },
    },
  } = props;
  const defaultPaymentType = _.get(paymentTypeDetails, "defaultPaymentType");
  const nonBranchSpecificPaymentDetails = _.filter(
    _.get(paymentTypeDetails, "paymentTypesForBranches"),
    (paymentTypeForBranches) => _.isEmpty(paymentTypeForBranches.branchIds),
  );

  const defaultPaymentDetails = _.isEmpty(defaultPaymentType)
    ? _.first(nonBranchSpecificPaymentDetails)
    : _.find(nonBranchSpecificPaymentDetails, {
        paymentType: defaultPaymentType,
      }) || _.first(nonBranchSpecificPaymentDetails);

  console.log("Setting default business payment details:", defaultPaymentDetails);

  return defaultPaymentDetails;
};

export const getCurrentPaymentMethodDetails = (state, props) => {
  const {
    pageContext: {
      business: { paymentTypeDetails },
    },
  } = props;

  const {
    order: { branchId },
  } = state;

  const customLocationPaymentDetails = _.find(
    _.get(paymentTypeDetails, "paymentTypesForBranches"),
    (paymentType) => _.includes(paymentType.branchIds, branchId),
  );

  if (customLocationPaymentDetails) {
    console.log("Setting custom location payment details");
    return customLocationPaymentDetails;
  }

  return getDefaultPaymentMethodDetails(state, props);
};

export const isEditedByUser = (state, props) =>
  Boolean(getMenuItemConfiguration(state, props));

export const getOrderBranch = (state, props) => {
  const contextBranch = state.order.branchId
    ? _.find(props.pageContext.branches, { id: state.order.branchId })
    : props.pageContext.branches[0];

  const branchFromBranchesAvailabilty =
    !_.get(state, "locations.loadingBranchesAvailability") &&
    _.get(
      state,
      `locations.branchesAvailability.${_.get(contextBranch, "id")}`,
    );

  return { ...contextBranch, ...branchFromBranchesAvailabilty };
};

export const getOrderSelectedServingOption = (state, props) => {
  const {
    pageContext: { servingOptions,
    business: {
      appStyles: {
        skipServingOptionsPage
      }
    }
    },
  } = props;

  const defaultServingOption = skipServingOptionsPage
    ? CONSTANTS.SERVING_OPTION.DELIVERY
    : CONSTANTS.SERVING_OPTION.PICKUP;

  const {
    order: { servingOptionType = defaultServingOption },
  } = state;

  const selectedServingOption =
    _.find(servingOptions, { type: servingOptionType });

  return reformatTimeFrames(selectedServingOption);
};

export const getSizeOptions = (state, props) => {
  const {
    pageContext: { itemData: originalItemData },
  } = props;
  const { giftRedeem } = state;

  const currentBranch = getOrderBranch(state, props);
  const unavailablePosItemsIds = getUnavailablePosItemIds(state, props);

  const servingOption = getOrderSelectedServingOption(state, props);
  const specificSizeForGift =
    (giftRedeem.isDuringGiftRedeem || giftRedeem.isDuringDealRedeem) &&
    giftRedeem.currentStepIndex > -1 &&
    !_.isEmpty(giftRedeem.steps[giftRedeem.currentStepIndex]) &&
    giftRedeem.steps[giftRedeem.currentStepIndex].context &&
    giftRedeem.steps[giftRedeem.currentStepIndex].context.sizes;
  return originalItemData.optionsOrderData
    ? _.map(
        _.filter(originalItemData.optionsOrderData, (option) => {
          if (
            specificSizeForGift &&
            !_.includes(_.castArray(specificSizeForGift), option.menuItemId)
          ) {
            return false;
          }

          const isTempUnavailableInLocationSize = _.includes(
            unavailablePosItemsIds,
            _.get(
              _.first(
                _.get(
                  _.first(
                    _.get(option, "menuItemServingOptions.servingOptions"),
                  ),
                  "sizeOptions",
                ),
              ),
              "posItemId",
            ),
          );
          return (
            !_.includes(option.unavailableInBranches, currentBranch.id) &&
            !isTempUnavailableInLocationSize
          );
        }),
        (option) => ({
          ..._.pick(option, ["id", "itemName", "menuItemId"]),
          price: getSizeOptionPrice(option, {
            servingOptionId: servingOption.id,
            posInnerMenuId: getOrderBranch(state, props).innerPosMenuId,
          }),
          heldItemsSelectionTitle: _.get(originalItemData,"heldItemsSelectionTitle")
        }),
      )
    : null;
};

export const getUnavailablePosItemIds = (state, props) => {
  const {
    locations: { branchesAvailability },
  } = state;
  const currentBranch = getOrderBranch(state, props);

  if (
    !currentBranch ||
    !branchesAvailability[currentBranch.id] ||
    _.isEmpty(
      branchesAvailability[currentBranch.id].temporarilyUnavailablePosItems,
    )
  ) {
    return [];
  }
  return branchesAvailability[currentBranch.id].temporarilyUnavailablePosItems;
};

export const getConfiguredMenuItemOrderData = (state, props) => {
  const { giftRedeem } = state;

  const {
    pageContext: {
      itemData: originalItemDataOrWithSizeOptions,
      item: sourceItem,
    },
  } = props;

  const { innerPosMenuId: posInnerMenuId } = getOrderBranch(state, props);

  const menuItemConfiguration = getMenuItemConfiguration(state, props) || {};
  const unavailablePosItemsIds = getUnavailablePosItemIds(state, props);

  const {
    count = originalItemDataOrWithSizeOptions.minimumRequiredCount || 1,
    selectedSizeId: configuredSizeId = _.get(
      originalItemDataOrWithSizeOptions,
      "optionsOrderData.length",
    ) === 1
      ? originalItemDataOrWithSizeOptions.optionsOrderData[0].id
      : null,
    remark = null,
    selectedMenuSizeId,
    removedIngredients = [],
  } = menuItemConfiguration;

  const itemSizeOption = configuredSizeId
    ? _.find(
        originalItemDataOrWithSizeOptions.optionsOrderData,
        (optionOrderData) =>
          optionOrderData.menuItemId === configuredSizeId ||
          optionOrderData.id === configuredSizeId,
      )
    : null;

  const selectedSizeId = itemSizeOption ? configuredSizeId : null;

  const originalItemData =
    (originalItemDataOrWithSizeOptions.optionsOrderData && selectedSizeId
      ? _.cloneDeep(
          _.find(
            originalItemDataOrWithSizeOptions.optionsOrderData,
            (optionOrderData) =>
              optionOrderData.menuItemId === selectedSizeId ||
              optionOrderData.id === selectedSizeId,
          ),
        )
      : originalItemDataOrWithSizeOptions) || originalItemDataOrWithSizeOptions;

  const itemData =
    originalItemData.optionsOrderData && selectedSizeId
      ? _.cloneDeep(
          _.find(originalItemData.optionsOrderData, { id: selectedSizeId }),
        ) || _.cloneDeep(originalItemData)
      : _.cloneDeep(originalItemData);

  ////////////////////////////////////////////////////////////////////////////////////
  // For convenience itemData is mutated in this process, originalItemData is not.  //
  // originalItemData is used to reset user preferences if needed.                  //
  ////////////////////////////////////////////////////////////////////////////////////

  const orderItemComponentsConfigurationsByTitle = _.keyBy(
    menuItemConfiguration.orderItemComponents || [],
    "title",
  );

  const orderItemComponentsDefaultsByTitle = _.keyBy(
    originalItemData.orderItemComponents || [],
    "title",
  );

  //////////////////////////////////////////////////////////////////////////////////////////
  //BTW: here you can filter components (apply mutation to itemData.orderItemComponent)   //
  //////////////////////////////////////////////////////////////////////////////////////////

  _.forEach(itemData.orderItemComponents, (component) => {
    const componentConfiguration =
      orderItemComponentsConfigurationsByTitle[component.title];

    // if no component configuration use defaults
    applyOrderItemComponentConfiguration(
      itemData,
      selectedSizeId,
      selectedMenuSizeId,
      componentConfiguration ||
        orderItemComponentsDefaultsByTitle[component.title],
      unavailablePosItemsIds,
      { posInnerMenuId, giftRedeem },
    );
  });

  // filtering unavailable toppings:

  if (itemData.toppingsComponent) {
    _.forEach(
      itemData.toppingsComponent.toppingsComponentCategories,
      (category) => {
        // zeroing numOfMarkedToppings before applying chosen ones.
        // this is done imperativly for convinence;
        category.numOfMarkedToppings = 0;

        category.toppings = _.filter(category.toppings, (topping) => {
          topping.toppingOptions = _.filter(
            topping.toppingOptions,
            (toppingOption) => {
              const isGenerallyUnavailable = _.includes(
                unavailablePosItemsIds,
                toppingOption.posItemId,
              );

              const isUnavailbleForCurrentInnerMenu =
                !_.isEmpty(posInnerMenuId) &&
                toppingOption.posInnerMenuAvailability &&
                toppingOption.posInnerMenuAvailability.type === "EXCLUDED" &&
                _.includes(
                  toppingOption.posInnerMenuAvailability.posInnerMenuIds,
                  posInnerMenuId,
                );

              return !(
                isGenerallyUnavailable || isUnavailbleForCurrentInnerMenu
              );
            },
          );
          return !_.isEmpty(topping.toppingOptions);
        });
      },
    );
  }
  // applying configuration: (chosen + set numOfMarkedToppings)
  if (itemData.toppingsComponent) {
    _.forEach(
      _.keys(_.get(menuItemConfiguration, "toppings")),
      (toppingKey) => {
        const [categoryTitle, toppingTitle] = toppingKey.split("_");

        const toppingCategory = _.find(
          itemData.toppingsComponent.toppingsComponentCategories,
          { title: categoryTitle },
        );

        if (toppingCategory) {
          const topping = _.find(toppingCategory.toppings, {
            title: toppingTitle,
          });
          if (topping) {
            const selectedToppingOption =
              menuItemConfiguration.toppings[toppingKey];

            topping.chosenTime = selectedToppingOption.chosenTime;
            let isToppingChosen = false;
            topping.toppingOptions = _.map(
              topping.toppingOptions,
              (toppingOption) => {
                const isToppingOptionChosen =
                  selectedToppingOption.position &&
                  toppingOption.weight === selectedToppingOption.weight &&
                  toppingOption.position === selectedToppingOption.position;
                isToppingChosen = isToppingChosen || isToppingOptionChosen;

                return {
                  ...toppingOption,
                  chosen: Boolean(isToppingOptionChosen),
                  edited: true,
                  multiplierId: selectedToppingOption.multiplierId,
                };
              },
            );

            toppingCategory.numOfMarkedToppings = isToppingChosen
              ? (toppingCategory.numOfMarkedToppings || 0) + 1
              : toppingCategory.numOfMarkedToppings;
          }
        }
      },
    );
  }

  const applySelectedMenuSizeId = (configuredMenuItem, selectedMenuSizeId) =>
    _.set(
      configuredMenuItem,
      "menuItemServingOptions.selectedServingOption.selectedMenuSizeId",
      selectedMenuSizeId,
    );
  if (menuItemConfiguration.selectedMenuSizeId) {
    applySelectedMenuSizeId(itemData, menuItemConfiguration.selectedMenuSizeId);
  }

  // does not filter mandatory
  itemData.ingredients = _.map(itemData.ingredients, (ingredient) => ({
    ...ingredient,
    isSelected: !_.includes(removedIngredients, ingredient.name),
  }));

  return {
    ...itemData,
    selectedSizeId,
    sourceItem,
    count,
    remark,
    firstComponentWithError: getFirstComponentWithError(itemData),
  };
};

const applyOrderItemComponentConfiguration = (
  itemData,
  selectedSizeId,
  selectedMenuSizeId = null,
  orderItemComponentConfiguration,
  unavailablePosItemsIds,
  { posInnerMenuId, giftRedeem },
) => {
  const filterServingOptionSizes = (item) => {
    if (giftRedeem.isDuringDealRedeem) {
      const availablePosItemsIdsForGift = _.get(
        giftRedeem,
        `steps[${giftRedeem.currentStepIndex}].context.stepPosItemIds`,
      );
      item.menuItemServingOptions.servingOptions = _.map(
        item.menuItemServingOptions.servingOptions,
        (servingOption) => {
          return {
            ...servingOption,
            sizeOptions: _.filter(servingOption.sizeOptions, (sizeOption) =>
              _.includes(availablePosItemsIdsForGift, sizeOption.posItemId),
            ),
          };
        },
      );
    }
  };

  filterServingOptionSizes(itemData);

  const setChosenOrderItemComponentOptionsAndRemoveUnavailable = (
    item,
    componentConfiguration,
  ) => {
    const orderItemComponent = _.find(item.orderItemComponents, {
      title: componentConfiguration.title,
    });
    orderItemComponent.componentOptions = _.filter(
      orderItemComponent.componentOptions,
      (option) => {
        const isGenerallyUnavailable = _.includes(
          unavailablePosItemsIds,
          option.posItemId,
        );

        const isUnavailbleForCurrentInnerMenu =
          !_.isEmpty(posInnerMenuId) &&
          option.posInnerMenuAvailability &&
          option.posInnerMenuAvailability.availabilityType === "EXCLUDED" &&
          _.includes(
            option.posInnerMenuAvailability.posInnerMenuIds,
            posInnerMenuId,
          );

        return !(isGenerallyUnavailable || isUnavailbleForCurrentInnerMenu);
      },
    );

    const componentOptionsIds = _.map(
      orderItemComponent.componentOptions,
      "id",
    );

    orderItemComponent.chosenOrderItemComponentOptions = _.filter(
      componentConfiguration.chosenOrderItemComponentOptions,
      (optId) => _.includes(componentOptionsIds, optId),
    );
  };

  const setChosenServingSize = (item) => {
    if (
      selectedMenuSizeId &&
      item.menuItemServingOptions.selectedServingOption &&
      item.menuItemServingOptions.selectedServingOption.selectedMenuSizeId
    ) {
      console.log("setting size: ", selectedMenuSizeId);
      item.menuItemServingOptions.selectedServingOption.selectedMenuSizeId = selectedMenuSizeId;
    }
    // check if selectedSizeIsAvailable for deals
    if (giftRedeem.isDuringDealRedeem) {
      const selectedServingOption = _.find(
        item.menuItemServingOptions.servingOptions,
        {
          servingOptionId:
            item.menuItemServingOptions.selectedServingOption
              .selectedServingOptionId,
        },
      );
      const hasThisSize = _.find(selectedServingOption.sizeOptions, {
        menuSizeId:
          item.menuItemServingOptions.selectedServingOption.selectedMenuSizeId,
      });
      if (!hasThisSize) {
        console.log(
          "selected size not available, choosing first size available",
        );
        item.menuItemServingOptions.selectedServingOption.selectedMenuSizeId = _.get(
          selectedServingOption,
          "sizeOptions[0].menuSizeId",
        );
      }
    }
  };

  if (Array.isArray(itemData.optionsOrderData) && selectedSizeId) {
    const selectedSubMenuItem = _.find(itemData.optionsOrderData, {
      id: selectedSizeId,
    });

    setChosenOrderItemComponentOptions(
      selectedSubMenuItem,
      orderItemComponentConfiguration,
    );

    return itemData;
  }

  setChosenOrderItemComponentOptionsAndRemoveUnavailable(
    itemData,
    orderItemComponentConfiguration,
  );
  setChosenServingSize(itemData);

  return itemData;
};

export const getOrderValidationErrors = (state, props) => {
  const { order } = state;

  if (!order.checkoutResponse)
    return {
      errorsByItemIndex: [],
      generalErrors: [],
    };

  const errorsWithItemIndex = _.filter(
    order.checkoutResponse.orderValidationResults,
    (validationResult) => _.has(validationResult, "itemIndex"),
  );

  // used in order to create a list of warning that should not be displayed as general error because they are already
  // mentioned by items validation errors
  const itemsValidationWarnings = _.uniq(
    _.map(errorsWithItemIndex, "orderValidationWarning"),
  );

  const generalErrors = _.filter(
    _.uniqBy(
      _.filter(
        order.checkoutResponse.orderValidationResults,
        (validationResult) => !_.has(validationResult, "itemIndex"),
      ),
      "orderValidationWarning",
    ),
    (validationError) =>
      !_.includes(
        itemsValidationWarnings,
        validationError.orderValidationWarning,
      ) && validationError.orderValidationResult !== 0,
  );
  return {
    errorsByItemIndex: _.keyBy(errorsWithItemIndex, "itemIndex"),
    generalErrors,
  };
};

const hasGifts = (order) =>
  order.couponsBatchIndex != -1 &&
  order.checkoutResponse &&
  order.checkoutResponse.couponBatchesResponse.batches &&
  order.checkoutResponse.couponBatchesResponse.batches[order.couponsBatchIndex];

const useChargeCardOnly = (order, finalPrice) =>
  !order.isChargeCardDeselected &&
  finalPrice &&
  order.checkoutResponse &&
  order.checkoutResponse.orderUserBalanceDetails &&
  order.checkoutResponse.orderUserBalanceDetails.balance &&
  order.checkoutResponse.orderUserBalanceDetails.amountApplicableFromBalance >=
    finalPrice &&
  order.checkoutResponse.orderUserBalanceDetails.balance.amount >= finalPrice;

const useGiftCardOnly = (order, finalPrice) => {
  return (
    order.giftCardsToRedeem &&
    finalPrice <=
      _.sumBy(order.giftCardsToRedeem, (giftCard) => giftCard.balance)
  );
};

export const getTotalExternalGiftCardRedeemAmount = (state, props) => {
  const {
    order,
    app: { isSSR },
  } = state;
  if (isSSR) {
    return 0;
  }

  if (order.executePayment) {
    return 0;
  }

  return order && order.giftCardsToRedeem
    ? _.sumBy(order.giftCardsToRedeem, (giftCard) =>
        _.get(giftCard, "redeemAmount") ? _.get(giftCard, "redeemAmount") : 0,
      )
    : 0;
};

export const shouldHidePayment = (state, props) => {
  const {
    user,
    order: { totalExternalGiftCardRedeemAmount },
    app: { isSSR },
  } = state;
  const finalPriceToPayWithCreditOrCash =
    getPriceDetailsForCheckout(state, props).total -
    getTotalExternalGiftCardRedeemAmount(state, props);
  console.log({ finalPriceToPayWithCreditOrCash });
  return (
    userHasEnoughLoadedOnCardForOrder(state, props) ||
    finalPriceToPayWithCreditOrCash < 0.01
  );

  // const {
  //   pageContext: { businessAppConfiguration, kioskMode },
  // } = props;
  // const {
  //   loadCardEnabled,
  //   hasCashback,
  //   subtractNonRewardableAmountsForLoyalty,
  // } = businessAppConfiguration;

  // if (isSSR) {
  //   return true;
  // }
  // if (kioskMode) {
  //   return true;
  // }

  // const priceDetails = getPriceDetails(order);
  // const tipAmount = _.get(priceDetails, "tipAmount", 0);

  // if (tipAmount && subtractNonRewardableAmountsForLoyalty) {
  //   return false;
  // }
  // if (user.loggedIn) {
  //   if (hasGifts(order)) {
  //     const selectedBatch =
  //       order.checkoutResponse.couponBatchesResponse.batches[
  //         order.couponsBatchIndex
  //       ];
  //     const priceAfterGiftDiscount =
  //       ((loadCardEnabled || hasCashback) && !order.isChargeCardDeselected
  //         ? selectedBatch.realPriceAfterDiscount
  //         : selectedBatch.priceAfterDiscount) + tipAmount;

  //     if (priceAfterGiftDiscount === 0) {
  //       return true;
  //     }

  //     // handle gift cards
  //     if (useGiftCardOnly(order, priceAfterGiftDiscount)) {
  //       return true;
  //     }

  //     return false;
  //   }

  //   if (loadCardEnabled || hasCashback) {
  //     if (
  //       useChargeCardOnly(
  //         order,
  //         order.checkoutResponse && order.checkoutResponse.finalPrice,
  //       )
  //     ) {
  //       return true;
  //     }
  //   }

  //   if (
  //     order &&
  //     order.checkoutResponse &&
  //     useGiftCardOnly(order, order.checkoutResponse.finalPrice)
  //   ) {
  //     return true;
  //   }

  //   return false;
  // }

  // // handle promo code
  // if (_.get(priceDetails, "total", 0) === 0) {
  //   return true;
  // }

  // return false;
};

export const shouldHideCash = (state, props) => {
  const {
    user,
    order,
    app: { isSSR },
  } = state;

  const {
    pageContext: {
      businessAppConfiguration: { loadCardEnabled, hasCashback },
      kioskMode,
    },
  } = props;

  if (isSSR) {
    return true;
  }
  if (kioskMode) {
    return true;
  }

  if (user.loggedIn) {
    if (hasGifts(order)) {
      const priceDetails = getPriceDetails(order);
      const tipAmount = _.get(priceDetails, "tipAmount", 0);

      const priceAfterGiftDiscount =
        order.checkoutResponse.couponBatchesResponse.batches[
          order.couponsBatchIndex
        ].priceAfterDiscount + tipAmount;
      if (priceAfterGiftDiscount > 0 && tipAmount > 0) {
        return true;
      }

      if (useGiftCardOnly(order, priceAfterGiftDiscount)) {
        return true;
      }

      return false;
    }

    if (
      order &&
      order.checkoutResponse &&
      useGiftCardOnly(order, order.checkoutResponse.finalPrice)
    ) {
      return true;
    }
  }

  return false;
};

export const isSavingOrder = (state, props) => {
  return (
    !_.isEmpty(state.order.savingRequestsTimestamps) &&
    (!_.isEmpty(state.order.orderItems) || !_.isEmpty(state.order.promoCodes))
  );
};

export const getOrderItemsForCheckout = (state, props) => {
  const {
    order: { checkoutResponse, orderItems },
  } = state;
  return getOrderItems(checkoutResponse, orderItems);
};

export const getOrderItemsForPayment = (state, props) => {
  const {
    order: { lastOrderDetails },
  } = state;
  if (!lastOrderDetails) return [];
  return getOrderItems(
    lastOrderDetails.checkoutResponse,
    lastOrderDetails.orderItems,
  );
};

const itemHasSelectedServingSize = ({ configuredMenuItemOrderData }) =>
  configuredMenuItemOrderData.menuItemServingOptions &&
  configuredMenuItemOrderData.menuItemServingOptions.selectedServingOption &&
  configuredMenuItemOrderData.menuItemServingOptions.selectedServingOption
    .selectedMenuSizeId &&
  configuredMenuItemOrderData.menuItemServingOptions.selectedServingOption
    .selectedServingOptionId;

const getItemSelectedSize = (item) => {
  if (!itemHasSelectedServingSize(item)) {
    return null;
  } else {
    const {
      configuredMenuItemOrderData: {
        menuItemServingOptions: {
          selectedServingOption: {
            selectedMenuSizeId,
            selectedServingOptionId,
          },
          servingOptions: itemServingOptions,
        },
      },
    } = item;
    const selectedServingOption = _.find(itemServingOptions, {
      servingOptionId: selectedServingOptionId,
    });
    if (_.isEmpty(selectedServingOption)) {
      return null;
    }
    const selectedSizeOption =
      _.get(selectedServingOption, "sizeOptions.length") > 1 &&
      _.find(selectedServingOption.sizeOptions, {
        menuSizeId: selectedMenuSizeId,
      });
    if (!selectedSizeOption) {
      return null;
    }
    return selectedSizeOption.menuSizeRef;
  }
};

// order is the savedOrder recieved form updateOrder API call,
// orderConfiguration is from the state (saved when added to order);
const getOrderItems = (checkoutResponse, orderItems) => {
  if (!checkoutResponse || !orderItems) return [];

  const sourceItemsForMenuItems = _.keyBy(
    _.filter(
      orderItems,
      (item) =>
        (item.menuItemConfiguration &&
          item.menuItemConfiguration.selectedSizeId) ||
        item.sourceItem,
    ),
    "configuredMenuItemOrderData.menuItemId",
  );

  const checkedoutOrderItems = _.map(
    _.get(checkoutResponse, "order.orderItems") || [],
    (item) => {
      const { configuredMenuItemOrderData } = item;

      const sourceItem = _.get(
        sourceItemsForMenuItems[configuredMenuItemOrderData.menuItemId],
        "sourceItem",
      );

      const sourceItemName = sourceItem && sourceItem.name;

      const title = sourceItemName || configuredMenuItemOrderData.itemName;

      const sizeSelectorName = _.get(sourceItem, "heldItemsSelectionTitle") ? _.get(sourceItem, "heldItemsSelectionTitle") : "Size";

      // TODO: fix prices

      const orderItemComponents = _.map(
        _.filter(
          configuredMenuItemOrderData.orderItemComponents,
          "chosenOrderItemComponentOptions",
        ),
        (orderItemComponent) => ({
          title: orderItemComponent.title,
          selectedOptions: _.map(
            _.filter(
              _.map(orderItemComponent.chosenOrderItemComponentOptions, (id) =>
                _.find(orderItemComponent.componentOptions, { id }),
              ),
              _.negate(_.isEmpty),
            ),
            (option) => ({
              ...option,
              price: option.price,
            }),
          ),
        }),
      );

      const toppingsCategories =
        configuredMenuItemOrderData.toppingsComponent &&
        _.map(
          configuredMenuItemOrderData.toppingsComponent
            .toppingsComponentCategories,
          (toppingCategory) => {
            return {
              ..._.pick(toppingCategory, [
                "minNumOfMarkedComponentOptions",
                "maxNumOfMarkedOptionsIncluded",
                "maxNumOfMarkedOptions",
              ]),
              title: toppingCategory.title,
              toppings: _.filter(
                toppingCategory.toppings,
                (topping) => !!_.find(topping.toppingOptions, "chosen"),
              ),
            };
          },
        );

      const servingSize = getItemSelectedSize(item);

      const itemSizeName =
        sourceItemName &&
        configuredMenuItemOrderData.itemName !== title &&
        configuredMenuItemOrderData.itemName;

      return {
        menuItemId: configuredMenuItemOrderData.menuItemId,
        title,
        size: itemSizeName,
        servingSize,
        sizeSelectorName,
        orderItemComponents,
        toppingsCategories,
        ingredients: configuredMenuItemOrderData.ingredients,
        useCategoryImage: _.get(
          sourceItem,
          "useCategoryImage",
          configuredMenuItemOrderData.useCategoryImage,
        ),
        imageKey: _.get(
          sourceItem,
          "imageKey",
          configuredMenuItemOrderData.imageKey,
        ),
        imagePreview: _.get(
          sourceItem,
          "imagePreview",
          configuredMenuItemOrderData.imagePreview,
        ),
        price: item.price,
        count: item.count,
        minimumRequiredCount:
          item.configuredMenuItemOrderData.minimumRequiredCount,
        maximumAllowedCount:
          item.configuredMenuItemOrderData.maximumAllowedCount,
        remark: item.remark,
      };
    },
  );
  return checkedoutOrderItems;
};

export const getOrderDiscountItemsForPayment = (state, props) => {
  const {
    pageContext: { deals },
  } = props;
  const {
    order: { lastOrderDetails, checkoutResponse },
  } = state;

  if (!lastOrderDetails) {
    if (_.isEmpty(checkoutResponse)) {
      return [];
    }
    return getOrderDiscountItemsWithImages(checkoutResponse, deals);
  }
  return getOrderDiscountItemsWithImages(
    lastOrderDetails.checkoutResponse,
    deals,
  );
};

export const getOrderDiscountItemsForCheckout = (state, props) => {
  const {
    pageContext: { deals },
  } = props;

  const {
    order: { checkoutResponse },
  } = state;
  return getOrderDiscountItemsWithImages(checkoutResponse, deals);
};

const getOrderDiscountItemsWithImages = (checkoutResponse, deals) => {
  if (!checkoutResponse || !checkoutResponse.order) {
    return [];
  }
  const dealsById = _.keyBy(deals, "id");
  const {
    order: { discountItems },
  } = checkoutResponse;
  return _.map(discountItems, (discountItem) => ({
    ...discountItem,
    deal: dealsById[discountItem.discountRuleId],
  }));
};

const getCurrentLocation = (state, props) => {
  const {
    order: { branchId, servingOptionType },
    locations,
  } = state;

  return (
    servingOptionType &&
    branchId &&
    !_.isEmpty(locations.branchesAvailability) &&
    locations.branchesAvailability[branchId]
  );
};

export const getIncludedTaxPercentage = (state, props) => {
  const {
    order: { branchId, servingOptionType },
    locations,
  } = state;
  const currentLocation = getCurrentLocation(state, props);
  if (
    currentLocation &&
    currentLocation.servingOptionToIncludedTaxPercentage &&
    currentLocation.servingOptionToIncludedTaxPercentage[servingOptionType]
  ) {
    const includedTaxPercentage =
      currentLocation.servingOptionToIncludedTaxPercentage[servingOptionType];

    return includedTaxPercentage;
  }
  return null;
};

const getPriceOnReceipt = ({
  priceDetails,
  loadChargeCardBenefitPercentage,
}) => {
  const tax = priceDetails.tax || 0;
  const chargeCardDiscount = priceDetails.chargeCardDiscount || 0;
  const totalDiscount = priceDetails.totalDiscount || 0;

  return (
    priceDetails.subtotal +
    tax -
    totalDiscount -
    loadChargeCardBenefitPercentage * chargeCardDiscount
  );
};

export const getPriceDetailsForCheckout = (state, props) => {
  const {
    order: {
      checkoutResponse,
      couponsBatchIndex,
      isChargeCardDeselected,
      branchId,
      servingOptionType,
      deliveryTipPercentage,
      deliveryTipCustomValue,
      servingOptionTipPercentage,
      servingOptionTipCustomValue,
    },
    locations,
  } = state;

  const currentLocation = getCurrentLocation(state, props);
  const loadCardEnabled = canUserLoadChargeCard(state, props);
  const includedTaxPercentage = getIncludedTaxPercentage(state, props);

  const priceDetails = getPriceDetails({
    checkoutResponse,
    couponsBatchIndex,
    isChargeCardDeselected,
    includedTaxPercentage,
    deliveryTipPercentage,
    deliveryTipCustomValue,
    servingOptionTipPercentage,
    servingOptionTipCustomValue,
  });

  if (loadCardEnabled && priceDetails.chargeCardDiscount) {
    const loadChargeCardBenefitPercentage = getLoadChargeCardBenefitInPercentage(
      state,
      props,
    );
    return {
      ...priceDetails,
      priceOnReceipt: getPriceOnReceipt({
        priceDetails,
        loadChargeCardBenefitPercentage,
      }),
    };
  }
  return priceDetails;
};

export const getTipAmountFromCheckoutOrder = (checkoutResponse) => {
  const deliveryTip = _.get(
    checkoutResponse,
    "order.deliveryAddress.tipAmount",
    0,
  );

  if (_.get(checkoutResponse, "order.orderTip")) {
    const tipAmount = _.get(checkoutResponse, "order.orderTip.tipAmount", 0);
    return tipAmount;
  }
  if (deliveryTip) {
    return deliveryTip;
  }

  return 0;
};

export const getPriceDetailsForPayment = (state, props) => {
  const {
    order: { lastOrderDetails },
  } = state;
  if (!lastOrderDetails) return {};
  const includedTaxPercentage = getIncludedTaxPercentage(state, props);
  const loadCardEnabled = canUserLoadChargeCard(state, props);

  const priceDetails = getPriceDetails({
    checkoutResponse: lastOrderDetails.checkoutResponse,
    couponsBatchIndex: lastOrderDetails.couponsBatchIndex,
    isChargeCardDeselected: lastOrderDetails.isChargeCardDeselected,
    includedTaxPercentage,
    deliveryTipPercentage: lastOrderDetails.deliveryTipPercentage,
    deliveryTipCustomValue: lastOrderDetails.deliveryTipCustomValue,
    servingOptionTipPercentage: lastOrderDetails.servingOptionTipPercentage,
    servingOptionTipCustomValue: lastOrderDetails.servingOptionTipCustomValue,
  });

  if (loadCardEnabled && priceDetails.chargeCardDiscount) {
    const loadChargeCardBenefitPercentage = getLoadChargeCardBenefitInPercentage(
      state,
      props,
    );
    return {
      ...priceDetails,
      priceOnReceipt: getPriceOnReceipt({
        priceDetails,
        loadChargeCardBenefitPercentage,
      }),
    };
  }
  return priceDetails;
};

const getPriceDetails = ({
  checkoutResponse,
  couponsBatchIndex,
  isChargeCardDeselected,
  includedTaxPercentage,
  deliveryTipPercentage,
  deliveryTipCustomValue,
  servingOptionTipPercentage,
  servingOptionTipCustomValue,
}) => {
  if (!checkoutResponse) return {};
  const { order } = checkoutResponse;

  const deliveryPrice = _.get(order, "deliveryPrice");
  const chargeCardAmount = _.get(
    checkoutResponse,
    "orderUserBalanceDetails.amountApplicableFromBalance",
  );
  const hasCardDiscount = _.isNumber(chargeCardAmount) && chargeCardAmount > 0;
  const serviceCharge = Number(
    (_.get(checkoutResponse, "order.serviceChargeAmount") || 0).toFixed(2),
  );

  const applyTipAmountIfNeeded = (priceDetails) => {
    if (!(_.isNumber(deliveryPrice) || servingOptionTipPercentage)) {
      return priceDetails;
    }
    const tipAmountFromCheckout = getTipAmountFromCheckoutOrder(
      checkoutResponse,
    );

    const isGivingTip = Boolean(
      tipAmountFromCheckout ||
        deliveryTipPercentage ||
        servingOptionTipPercentage,
    );

    const currentSubTotalWithoutDeliveryFeeAndTip =
      priceDetails.subtotal - (priceDetails.deliveryPrice || 0);

    const isCustomTip =
      deliveryTipPercentage === CONSTANTS.CUSTOM_TIP_MODE ||
      servingOptionTipPercentage === CONSTANTS.CUSTOM_TIP_MODE;
    const tipAmount = Number(
      (isGivingTip
        ? isCustomTip
          ? deliveryTipCustomValue || servingOptionTipCustomValue || 0
          : currentSubTotalWithoutDeliveryFeeAndTip *
            (deliveryTipPercentage || servingOptionTipPercentage)
        : 0
      ).toFixed(2),
    ) || 0;


    const preTaxDeltaFromCurrent = tipAmount - tipAmountFromCheckout;
    const priceDetailsWithUpdatedTip = {
      ...priceDetails,
      deliveryPrice: priceDetails.deliveryPrice
        ? priceDetails.deliveryPrice + preTaxDeltaFromCurrent
        : priceDetails.deliveryPrice, // falasy
      subtotal: priceDetails.subtotal + preTaxDeltaFromCurrent,
      total: priceDetails.total + preTaxDeltaFromCurrent,
    };
    const chosenTipPercentage =
      deliveryTipPercentage || servingOptionTipPercentage;
    return {
      ...priceDetailsWithUpdatedTip,
      tipAmount,
      chosenTipPercentage:
        isCustomTip || !_.isNumber(chosenTipPercentage)
          ? null
          : chosenTipPercentage,
    };
  };

  const applyIncludedTaxIfNeeded = (priceDetails) =>
    includedTaxPercentage
      ? {
          deliveryPrice,
          ...priceDetails,
          subtotal:
            priceDetails.total -
            (includedTaxPercentage / 100.0) * priceDetails.total,
          tax: (includedTaxPercentage / 100.0) * priceDetails.total,
        }
      : priceDetails;

  const applyChargeCardDiscount = (priceDetails) => {
    if (!hasCardDiscount || isChargeCardDeselected) {
      return priceDetails;
    }

    const totalPrice = priceDetails.total;

    const chargeCardDiscount =
      hasCoupons(checkoutResponse) && couponsBatchIndex > -1
        ? checkoutResponse.couponBatchesResponse.batches[couponsBatchIndex]
            .amountApplicableFromBalance
        : totalPrice > chargeCardAmount
        ? chargeCardAmount
        : totalPrice;

    return {
      ...priceDetails,
      chargeCardDiscount,
      total: totalPrice - chargeCardDiscount,
    };
  };

  if (hasCoupons(checkoutResponse)) {
    if (couponsBatchIndex > -1) {
      const {
        priceAfterDiscount,
        realPriceAfterDiscount, // in case that charge card is used
        totalDiscount,
        totalDiscountBeforeTax,
        taxAmount,
      } = checkoutResponse.couponBatchesResponse.batches[couponsBatchIndex];

      return applyChargeCardDiscount(
        applyTipAmountIfNeeded(
          applyIncludedTaxIfNeeded({
            deliveryPrice,
            ..._.get(checkoutResponse, "order.detailedFinalPrice"),
            totalDiscount,
            totalDiscountBeforeTax,
            totalTaxOnDiscount: taxAmount,
            total: priceAfterDiscount,
            ...(serviceCharge > 0 && { serviceCharge }),
          }),
        ),
      );
    }
  }

  return applyChargeCardDiscount(
    applyTipAmountIfNeeded(
      applyIncludedTaxIfNeeded({
        deliveryPrice,
        ..._.get(checkoutResponse, "order.detailedFinalPrice"),
        ...(serviceCharge > 0 && { serviceCharge }),
      }),
    ),
  );
};

let subItemIdToHeldMenuItemId = null;

const getSubItemIdToHeldMenuItemId = (menuData, subItemId) => {
  // init hashmap if needed:
  subItemIdToHeldMenuItemId =
    subItemIdToHeldMenuItemId ||
    _.reduce(
      _.flatMap(_.filter(menuData, "holdsItems"), "items"),
      (subItemIdToHeldMenuItemId, item) => {
        const sizeOptionToMenuItemId = _.fromPairs(
          _.map(item.sizeOptionsIds, (sizeOptionId) => [sizeOptionId, item.id]),
        );

        return {
          ...subItemIdToHeldMenuItemId,
          ...sizeOptionToMenuItemId,
        };
      },
      {},
    );

  // return according to map or fallback to same items (that has no sizes accroding to menu data)
  return subItemIdToHeldMenuItemId[subItemId] || subItemId;
};

let menuItemsById = null;

export const getUpsales = (state, props) => {
  const {
    pageContext: { menuData },
  } = props;
  const { order } = state;

  if (!order.checkoutResponse || _.isEmpty(order.checkoutResponse.upsales)) {
    return [];
  }
  menuItemsById = menuItemsById || _.keyBy(_.flatMap(menuData, "items"), "id");

  return _.reduce(
    order.checkoutResponse.upsales,
    (upsales, upsale) => {
      const clonedUpsale = _.cloneDeep(upsale);
      clonedUpsale.itemsToAdd = _.map(clonedUpsale.itemsToAdd, (item) => {
        const menuItemId = getSubItemIdToHeldMenuItemId(
          menuData,
          item.menuItemId,
        );

        return {
          ...item,
          menuItemId,
          ...menuItemsById[menuItemId],
        };
      });
      upsales.push(clonedUpsale);
      return upsales;
    },
    [],
  );
};

export const getCurrentBranch = (state, props) => {
  const {
    pageContext: { branches },
  } = props;
  const { order } = state;

  return _.find(branches, { id: order.branchId });
};

export const getAvailableLocations = (state, props) => {
  const { locations } = state;
  const { branchesAvailability } = locations;

  return {
    ...locations,
    branchesAvailability,
  };
};
export const getServingOptionDetails = (state, props) => {
  return {
    branch: getCurrentBranch(state, props),
    servingOption: getOrderSelectedServingOption(state, props),
  };
};

export const getServingTime = (state, props) => {
  const { order, locations } = state;

  if (locations.loadingBranchesAvailability) {
    return {};
  }

  const branchesAvailability = getBranchesAvailability(state, props);
  if (!branchesAvailability) {
    return {};
  }
  const selectedServingOption = getOrderSelectedServingOption(state, props);
  const needsAddress = selectedServingOption.needsAddress;

  const deliveryOptionForBranch = _.find(
    locations.deliveryOptions,
    (branch) => branch.branchId === branchesAvailability.branchId,
  );

  const servingDelay =
    needsAddress && !_.isEmpty(deliveryOptionForBranch)
      ? deliveryOptionForBranch.deliveryTimeDuration
      : branchesAvailability.delayedOrderInterval;

  const prepTime =
    _.get(order, "prepTime") ||
    (branchesAvailability.servingOptionTypeToPrepTime &&
      branchesAvailability.servingOptionTypeToPrepTime[
        selectedServingOption.type
      ]) ||
    selectedServingOption.prepTime ||
    30;

  return needsAddress ? prepTime + servingDelay : prepTime;
};

export const BRANCH_PICKUP_TIMES_ERRORS = {
  FAILED_LOADING_BRANCH_AVAILABILITY: "FAILED_LOADING_BRANCH_AVAILABILITY",
  BRANCH_AVAILABILITY_NOT_FOUND: "BRANCH_AVAILABILITY_NOT_FOUND",
};

export const getBranchPickupTimes = (state, props) => {
  const {
    locations,
    order: { branchId },
  } = state;

  if (locations.loadingBranchesAvailability) {
    return {
      data: null,
      loading: true,
      status: null,
      error: null,
    };
  }

  if (locations.loadBranchesAvailabilityError) {
    console.warn("failed to load branches availability");
    return {
      data: null,
      loading: false,
      status: null,
      error: BRANCH_PICKUP_TIMES_ERRORS.FAILED_LOADING_BRANCH_AVAILABILITY,
    };
  }

  const branchAvailability = _.get(
    getBranchesAvailability(state, props),
    branchId
  );

  if (
    !branchAvailability ||
    !_.get(branchAvailability, "detailedOpenHours.openHours")
  ) {
    console.warn(`branch availability not found for branchId: ${branchId}`);
    return {
      data: null,
      loading: false,
      status: null,
      error: BRANCH_PICKUP_TIMES_ERRORS.BRANCH_AVAILABILITY_NOT_FOUND,
    };
  }

  return getPickupTimes(state, props);
};

export const hasCouponsForCheckout = (state, props) => {
  const {
    order: { checkoutResponse },
  } = state;
  return hasCoupons(checkoutResponse);
};

export const hasCouponsForPayment = (state, props) => {
  const {
    order: {
      lastOrderDetails: { checkoutResponse },
    },
  } = state;
  return hasCoupons(checkoutResponse);
};

const hasCoupons = (checkoutResponse) => {
  return Boolean(
    checkoutResponse &&
      checkoutResponse.couponBatchesResponse &&
      Array.isArray(checkoutResponse.couponBatchesResponse.batches) &&
      checkoutResponse.couponBatchesResponse.batches.length > 0,
  );
};

const getServingTimeInTimeZone = (
  state,
  timeZoneStr,
  filterMenuItemsAccordingToSelectedPickupTime = false
) => {
  const {
    order: { pickupTime, futureServingTime },
  } = state;

  return pickupTime &&
    futureServingTime &&
    filterMenuItemsAccordingToSelectedPickupTime
    ? moment.tz(futureServingTime, timeZoneStr)
    : moment.tz(timeZoneStr);
};

export const getAvailableMenuData = (state, props) => {
  const {
    pageContext: {
      branches,
      menuData,
      businessAppConfiguration: {
        filterMenuItemsAccordingToSelectedPickupTime,
      },
    },
  } = props;
  const {
    order: { branchId },
    giftRedeem
  } = state;

  const branch = _.find(branches, { id: branchId });

  if (!branch) {
    // Fallback if something went wrong
    return menuData;
  }

  const unavailablePosItemsIds = getUnavailablePosItemIds(state, props);

  const { timeZoneStr } = branch;

  const servingTimeInTimeZone = getServingTimeInTimeZone(
    state,
    timeZoneStr,
    filterMenuItemsAccordingToSelectedPickupTime
  );

  const filterUnavailable = (category) => {
    const {
      availableHours,
      hideWhenUnavailable,
      isHidden,
      linkableWhenHidden,
    } = category;

    if (isHidden && !linkableWhenHidden){
      return false;
    }

    if (isHidden && linkableWhenHidden && !(_.get(giftRedeem, "isDuringDealRedeem") || _.get(giftRedeem, "isDuringGiftRedeem"))){
      return false;
    }

    if (_.isEmpty(availableHours) || !hideWhenUnavailable) {
      return true;
    }

    const relevantAvailableHours = _.filter(
      availableHours,
      (timeWindow) =>
        _.isEmpty(timeWindow.relevantBranchesIds) ||
        _.includes(timeWindow.relevantBranchesIds, branchId),
    );

    const getUTCDateInTimeZone = (utcDate) => {
      const formatedDate = moment(utcDate)
        .utc()
        .format("YYYY-MM-DD hh:mm:ss");
      return moment.tz(formatedDate, timeZoneStr);
    };

   return _.isEmpty(relevantAvailableHours) || Boolean(_.find(relevantAvailableHours, (timeWindow) => {
      const [openHour, openMinute] = timeWindow.openHour.split(":");
      const [closeHour, closeMinute] = timeWindow.closeHour.split(":");

      if (
        _.get(timeWindow, "relevantDateRanges") &&
        !_.isEmpty(_.get(timeWindow, "relevantDateRanges"))
      ) {
        const inRelevantDate = _.find(
          timeWindow.relevantDateRanges,
          (range) => {
            return servingTimeInTimeZone.isBetween(
              _.get(range, "startDate"),
              _.get(range, "endDate"),
            );
          },
        );
        if (!inRelevantDate) {          
          return false;
        }
      }

      if (timeWindow.day === servingTimeInTimeZone.day()) {
        if (timeWindow.openHour === timeWindow.closeHour) {
          return true;
        }

        const openTime = moment(servingTimeInTimeZone)
          .hours(openHour)
          .minutes(openMinute)
          .seconds(0);
        const closeTime = moment(servingTimeInTimeZone)
          .hours(closeHour)
          .minutes(closeMinute)
          .seconds(0);

        return servingTimeInTimeZone.isBetween(openTime, closeTime);
      }
      return false;
    }));
  };

  const filterUnavailableSubItems = (category) => {
    const updatedCategory = _.cloneDeep(category);
    if (!updatedCategory.holdsItems) {
      return category;
    }

    updatedCategory.items = _.filter(updatedCategory.items, filterUnavailable);
    return updatedCategory;
  };

  const availableMenuData = _.map(
    _.filter(menuData, filterUnavailable),
    filterUnavailableSubItems,
  );
  const servingOption = getOrderSelectedServingOption(state, props);

  const availableMenuDataForPlatform = _.filter(
    availableMenuData,
    (category) =>
      category.unavailableForPlatforms === null ||
      !_.includes(category.unavailableForPlatforms, SERVICE.PLATFORM_WEB)
  );

  // TODO: make this more readable by refactoring into functions
  const availableMenuDataWithItemsFilteredByBranch =
    // filter empty categories
    _.map(
      availableMenuDataForPlatform,
      (category) => {
        const updatedCategory = _.cloneDeep(category);
        const { items } = updatedCategory;

        return {
          ...updatedCategory,
          items: _.filter(
            _.filter(items, (item) => {
              if (item.sizeOptionsIds) {
                const emptySizeOptions =
                  item.sizeOptionsPosItemIds &&
                  _.isEmpty(
                    _.without(
                      item.sizeOptionsPosItemIds,
                      ...unavailablePosItemsIds,
                    ),
                  );
                if (emptySizeOptions) {
                  return false;
                }
              }
              if (item.servingOptions) {
                const itemServingOptionDetails = _.find(item.servingOptions, {
                  servingOptionId: servingOption.id,
                });
                if (_.isEmpty(itemServingOptionDetails)) {
                  return false;
                }
                if (
                  itemServingOptionDetails &&
                  itemServingOptionDetails.sizeOptions
                ) {
                  const emptySizeOptions = _.isEmpty(
                    _.without(
                      _.map(itemServingOptionDetails.sizeOptions, "posItemId"),
                      ...unavailablePosItemsIds,
                    ),
                  );
                  if (emptySizeOptions) {
                    return false;
                  }
                }
              }
              return true;
            }),
            (item) =>
              !item.isHidden &&
              (item.unavailableInBranches === null ||
                !_.includes(item.unavailableInBranches, branchId)),
          ),
        };
      },
    );

  return  _.filter(availableMenuDataWithItemsFilteredByBranch,(category) => category.items.length); 
};

export const isEditMode = (state, props) => {
  const { order } = state;
  const {
    pageContext: { item },
  } = props;

  return Boolean(order.editingItem) && order.editingItem.itemId === item.id;
};

export const allowCashPayment = (state, props) => {
  const {
    pageContext: { businessAppConfiguration },
  } = props;

  if (!businessAppConfiguration.hasCashPayments) {
    return false;
  }

  const currentBranch = getCurrentBranch(state, props);
  if (!currentBranch) {
    return false;
  }

  return !currentBranch.disableCashPayments;
};

export const allowCreditCardPayment = (state, props) => {
  const currentBranch = getCurrentBranch(state, props);
  if (!currentBranch) {
    return false;
  }
  return !currentBranch.disableCreditCardPayments;
};

export const getCashbackDiscounts = (state, props) => {
  const { user } = state;
  const cashbackPolicyRemainders = _.get(
    user,
    "loyaltyProfile.data.cashbackPolicyRemainders",
  );
  if (!cashbackPolicyRemainders) return [];

  return _.map(cashbackPolicyRemainders, (cashbackRemainder) => {
    const amountSpent =
      cashbackRemainder.cashback.conditionalAmount -
      cashbackRemainder.remainder;
    const percentFilled =
      (amountSpent * 100) / cashbackRemainder.cashback.conditionalAmount;
    return {
      policyId: cashbackRemainder.policyId,
      percentFilled,
      amountSpent,
      cashbackAmount: cashbackRemainder.cashback.cashbackAmount,
      amountToSpendForCashback: cashbackRemainder.cashback.conditionalAmount,
      amountLeftToSpend: cashbackRemainder.remainder,
    };
  });
};

const filterPosItemsAvailableForDeal = (deal, branchAvailability) => {
  if (_.get(deal, "discountRuleType") === "fixed") {
    return _.map(_.get(deal, "itemsCombinationQuery"), (combination) => {
      return {
        ...combination,
        posItemIds: _.filter(_.get(combination, "posItemIds"), (posItemId) => {
          return !_.includes(
            _.get(branchAvailability, "temporarilyUnavailablePosItems"),
            posItemId,
          );
        }),
      };
    });
  } else if (_.get(deal, "discountRuleType") === "percentage") {
    return [
      {
        posItemIds: _.filter(
          _.get(_.get(deal, "percentageParams.discountItems"), "posItemIds"),
          (posItemId) => {
            return !_.includes(
              _.get(branchAvailability, "temporarilyUnavailablePosItems"),
              posItemId,
            );
          },
        ),
      },
    ];
  }
  return {};
};

export const getDeals = (state, props) => {
  const {
    pageContext: { deals, menuData },
    data,
  } = props;

  const currentBranch = getOrderBranch(state, props);

  if (!currentBranch) {
    return [];
  }
  const branchAvailability = _.get(
    getBranchesAvailability(state, props),
    _.get(currentBranch, "id"),
  );

  // const DEAL_IMAGE_REF_PREFIX = "deal_";

  // const dealImages = _.omit(
  //   _.keyBy(data.allImageSharp.edges, (edge) =>
  //     edge.node.fixed
  //       ? edge.node.fixed.src
  //           .split("/static/")[1]
  //           .split(".")[0]
  //           .split(DEAL_IMAGE_REF_PREFIX)[1]
  //       : edge.node.fluid.src
  //           .split("/static/")[1]
  //           .split(".")[0]
  //           .split(DEAL_IMAGE_REF_PREFIX)[1],
  //   ),
  //   "undefined",
  // );

  const { timeZoneStr } = currentBranch;

  const servingTimeInTimeZone = getServingTimeInTimeZone(state, timeZoneStr);
  const selectedServingOption = getOrderSelectedServingOption(state, props);

  const selectedServingOptionId = _.get(selectedServingOption, "id");

  const filteredDeals = _.filter(deals, (deal) => {
    if (
      deal.servingOptionId &&
      deal.servingOptionId !== selectedServingOptionId
    ) {
      return false;
    }

    if (!deal.displayAsCoupon) {
      return false;
    }

    if (
      !_.isEmpty(deal.branches) &&
      !_.includes(deal.branches, currentBranch.id)
    ) {
      return false;
    }

    const availableCombinations = filterPosItemsAvailableForDeal(
      deal,
      branchAvailability
    );
    if (
      (_.get(deal, "discountRuleType") === "fixed" ||
        (_.get(deal, "discountRuleType") === "percentage" &&
          !_.isEmpty(_.get(deal, "percentageParams.discountItems")))) &&
      (_.isEmpty(availableCombinations) ||
        !_.find(
          availableCombinations,
          (combination) => !_.isEmpty(_.get(combination, "posItemIds")),
        ))
    ) {
      return false;
    }

    if (_.isEmpty(deal.availableHours)) {
      return true;
    }
    // TODO: Fix to match window to current branch
    const firstHoursWindowFit = _.find(deal.availableHours, (timeWindow) => {
      const [openHour, openMinute] = timeWindow.openHour.split(":");
      const [closeHour, closeMinute] = timeWindow.closeHour.split(":");
      if (timeWindow.day === servingTimeInTimeZone.day()) {
        if (timeWindow.openHour === timeWindow.closeHour) {
          return true;
        }
        const openTime = moment(servingTimeInTimeZone)
          .hours(openHour)
          .minutes(openMinute)
          .seconds(0);
        const closeTime = moment(servingTimeInTimeZone)
          .hours(closeHour)
          .minutes(closeMinute)
          .seconds(0);

        return servingTimeInTimeZone.isBetween(openTime, closeTime);
      }
      return false;
    });

    if (!firstHoursWindowFit) {
      return false;
    }

    return true;
  });

  return _.map(filteredDeals, (deal) => {
    const availableCombinations = filterPosItemsAvailableForDeal(
      deal,
      branchAvailability,
    );
    if (_.get(deal, "discountRuleType") === "fixed") {
      return {
        ...deal,
        posItemIdToMenuItemId: getPosItemToMenuItem(menuData, currentBranch, true),
        itemsCombinationQuery: availableCombinations,
      };
    } else if (
      _.get(deal, "discountRuleType") === "percentage" &&
      !_.isEmpty(_.get(deal, "percentageParams.discountItems"))
    ) {
      return {
        ...deal,
        posItemIdToMenuItemId:getPosItemToMenuItem(menuData, currentBranch, true),
        percentageParams: {
          ..._.get(deal, "percentageParams"),
          discountItems: _.first(availableCombinations),
        },
      };
    } else {
      return deal;
    }
  });
};

export const getDealGroup = (state, props) => {
  const {
    pageContext: { deals, menuData },
    order,
    data,
  } = props;
  const {
    giftRedeem: { isDuringDealRedeem, selectedDeal },
  } = state;

  if (
    !(
      isDuringDealRedeem &&
      selectedDeal &&
      selectedDeal.discountRuleType === "group"
    )
  ) {
    return [];
  }
  const currentBranch = getOrderBranch(state, props);

  if (!currentBranch) {
    return [];
  }

  const DEAL_IMAGE_REF_PREFIX = "deal_";

  const posItemIdToMenuItem = getPosItemToMenuItem(menuData, currentBranch);

  const groupChildDeals =
    !_.isEmpty(selectedDeal.groupParams) &&
    _.filter(
      deals,
      (subdeal) =>
        subdeal.discountRuleType === "fixed" &&
        _.includes(selectedDeal.groupParams.childDiscountRules, subdeal.id),
    );

  const toReturn = _.map(
    _.filter(
      groupChildDeals,
      (deal) =>
        _.isEmpty(deal.branches) || _.includes(deal.branches, currentBranch.id),
    ),
    (deal) => {
      const menuItemsCombinationQuery = _.map(
        deal.itemsCombinationQuery,
        ({ posItemIds }) => {
          return _.map(
            posItemIds,
            (posItemId) => posItemIdToMenuItem[posItemId],
          );
        },
      );

      return {
        ...deal,
        posItemIdToMenuItemId,
        menuItemsCombinationQuery,
        // imgSrc: dealImages[deal.id],
      };
    },
  );
  // console.log(toReturn);

  return toReturn;
};

// let itemImages = null;

// export const getItemImages = (state, props) => {
//   const { data } = props;
//   const MENU_ITEM_IMAGE_REF_PREFIX = "menuitem_";

//   if (!_.isEmpty(itemImages)) {
//     return itemImages;
//   }

//   itemImages = _.omit(
//     _.keyBy(data.allImageSharp.edges, (edge) =>
//       edge.node.fixed
//         ? edge.node.fixed.src
//             .split("/static/")[1]
//             .split(".")[0]
//             .split(MENU_ITEM_IMAGE_REF_PREFIX)[1]
//         : edge.node.fluid.src
//             .split("/static/")[1]
//             .split(".")[0]
//             .split(MENU_ITEM_IMAGE_REF_PREFIX)[1],
//     ),
//     "undefined",
//   );

//   return itemImages;
// };

const getOvernightSessionOpenHours = (weeklyOpenHours, weekday) =>
  _.find(weeklyOpenHours, (openHours) => {
    const overnightSessionWeekday =
      weekday + 1 === CONSTANTS.DAYS_OF_WEEK ? 0 : weekday + 1;
    return (
      openHours.day === overnightSessionWeekday &&
      openHours.openHour === CONSTANTS.MIDNIGHT
    );
  });

const getBranchesAvailabilityIntersectionWithBranches = (
  branches,
  branchesAvailability,
) => {
  return _.map(
    _.filter(branchesAvailability, (branchAvailability) =>
      _.find(branches, (branch) => branch.id === branchAvailability.branchId),
    ),
    (branchAvailability) => ({
      ...branchAvailability,
      branch: _.omit(
        _.find(branches, (branch) => branch.id === branchAvailability.branchId),
        ["id"],
      ),
    }),
  );
};

const getTimeFramesForSpecificDay = (timeFrames, day) =>
  _.filter(timeFrames, { day });

const getAvailabilityForFirstAvailableDayAfterToday = ({
  today,
  branchClosedDays,
  timeZoneStr,
  openHoursTimeFrames,
  servingOptionTimeFrames,
}) => {
  const firstAvailableDateAfterToday = getFirstAvailableDayAfterToday(
    today,
    branchClosedDays,
    timeZoneStr,
  ).toDate();

  const openHoursTimeFramesForDayAfterToday = getTimeFramesForSpecificDay(
    openHoursTimeFrames,
    moment.tz(firstAvailableDateAfterToday, timeZoneStr).day(),
  );

  const servingOptionTimeFramesForDayAfterToday = getTimeFramesForSpecificDay(
    servingOptionTimeFrames,
    moment.tz(firstAvailableDateAfterToday, timeZoneStr).day(),
  );

  const availableFrom = getAvailableFrom({
    firstAvailableDate: firstAvailableDateAfterToday,
    openHoursTimeFrames: openHoursTimeFramesForDayAfterToday,
    servingOptionTimeFrames: servingOptionTimeFramesForDayAfterToday,
    timeZoneStr,
  });

  return {
    availability: CONSTANTS.AVAILABLE_LATER,
    ...(availableFrom && { availableFrom }),
  };
};

const getBranchAvailability = ({
  branchAvailability,
  firstAvailableDate,
  deliveryOptions,
  selectedServingOption,
  branchClosedDays,
}) => {
  const timeZoneStr = branchAvailability.branch.timeZoneStr;
  const now = moment.tz(timeZoneStr);
  const branchId = branchAvailability.branchId;

  const isFirstAvailableDateAfterToday =
    moment.tz(firstAvailableDate, timeZoneStr).year() > now.year() ||
    moment.tz(firstAvailableDate, timeZoneStr).dayOfYear() > now.dayOfYear();
  const isFirstAvailableDateToday =
    moment.tz(firstAvailableDate, timeZoneStr).dayOfYear() === now.dayOfYear();

  const deliveryOptionForBranch = _.find(deliveryOptions, { branchId });
  const servingOptionTimeFramesChosen = selectedServingOption.needsAddress
    ? deliveryOptionForBranch
      ? deliveryOptionForBranch.timeFrames
      : []
    : selectedServingOption.timeFrames;

  const openHoursTimeFrames = getTimeFramesForSpecificDay(
    branchAvailability.detailedOpenHours.openHours,
    moment.tz(firstAvailableDate, timeZoneStr).day(),
  );
  const servingOptionTimeFrames = getTimeFramesForSpecificDay(
    servingOptionTimeFramesChosen,
    moment.tz(firstAvailableDate, timeZoneStr).day(),
  );

  if (branchAvailability.isUnavailable) {
    return { availability: CONSTANTS.TEMPORARILY_UNAVAILABLE };
  } else if (isFirstAvailableDateAfterToday) {
    const availableFrom = moment(branchAvailability.availableFrom);
    // getAvailableFrom({
    //   firstAvailableDate,
    //   openHoursTimeFrames,
    //   servingOptionTimeFrames,
    //   timeZoneStr,
    // });
    return {
      availability: CONSTANTS.AVAILABLE_LATER,
      ...(availableFrom && { availableFrom }),
    };
  } else if (isFirstAvailableDateToday) {
    const openHoursTimeFrameIntersectedWithNow = getTimeFrameIntersectWithTimeGiven(
      openHoursTimeFrames,
      now,
      timeZoneStr,
    );
    const noOpenHoursToday = _.isEmpty(openHoursTimeFrames);
    const noServingOptionTimeFrames = _.isEmpty(servingOptionTimeFrames);

    if (noOpenHoursToday) {
      const availableFrom = moment(branchAvailability.availableFrom);
      return getAvailabilityForFirstAvailableDayAfterToday({
        today: availableFrom,
        branchClosedDays,
        timeZoneStr,
        openHoursTimeFrames: branchAvailability.detailedOpenHours.openHours,
        servingOptionTimeFrames: servingOptionTimeFramesChosen,
      });
    } else if (
      !_.isEmpty(openHoursTimeFrames) &&
      _.isEmpty(openHoursTimeFrameIntersectedWithNow)
    ) {
      const openHourTimeFrameLaterToday = getTimeFrameAfterTimeGiven(
        openHoursTimeFrames,
        now,
        timeZoneStr,
      );

      if (openHourTimeFrameLaterToday) {
        return {
          availability: CONSTANTS.AVAILABLE_LATER,
          availableFrom: noServingOptionTimeFrames
            ? moment
                .tz(firstAvailableDate, timeZoneStr)
                .hours(openHourTimeFrameLaterToday.startHour)
                .minutes(openHourTimeFrameLaterToday.startMinute)
                .seconds(0)
                .toDate()
            : getAvailableFrom({
                firstAvailableDate,
                openHoursTimeFrames,
                servingOptionTimeFrames,
                timeZoneStr,
              }),
        };
      } else {
        return getAvailabilityForFirstAvailableDayAfterToday({
          today: firstAvailableDate,
          branchClosedDays,
          timeZoneStr,
          openHoursTimeFrames: branchAvailability.detailedOpenHours.openHours,
          servingOptionTimeFrames: servingOptionTimeFramesChosen,
        });
      }
    } else if (noServingOptionTimeFrames) {
      return {
        availability: CONSTANTS.AVAILABLE_NOW,
        availableFrom: undefined,
      };
    } else {
      const servingOptionTimeFrameIntersectedWithNow = getTimeFrameIntersectWithTimeGiven(
        servingOptionTimeFrames,
        now,
        timeZoneStr,
      );
      if (_.isEmpty(servingOptionTimeFrameIntersectedWithNow)) {
        const servingOptionTimeFrameLaterToday = getTimeFrameAfterTimeGiven(
          servingOptionTimeFrames,
          now,
          timeZoneStr,
        );
        if (servingOptionTimeFrameLaterToday) {
          return {
            availability: CONSTANTS.AVAILABLE_LATER,
            availableFrom: moment
              .tz(firstAvailableDate, timeZoneStr)
              .hours(servingOptionTimeFrameLaterToday.startHour)
              .minutes(servingOptionTimeFrameLaterToday.startMinute)
              .seconds(0)
              .toDate(),
          };
        } else {
          return getAvailabilityForFirstAvailableDayAfterToday({
            today: firstAvailableDate,
            branchClosedDays,
            timeZoneStr,
            openHoursTimeFrames: branchAvailability.detailedOpenHours.openHours,
            servingOptionTimeFrames: servingOptionTimeFramesChosen,
          });
        }
      } else {
        const timeFrame = getTimeFrameIntersection(
          openHoursTimeFrameIntersectedWithNow,
          servingOptionTimeFrameIntersectedWithNow,
        );

        if (!timeFrame) {
          const availableFrom = getAvailableFrom({
            firstAvailableDate,
            openHoursTimeFrames,
            servingOptionTimeFrames,
            timeZoneStr,
          });
          return {
            availability: CONSTANTS.AVAILABLE_LATER,
            ...(availableFrom && { availableFrom }),
          };
        } else {
          return {
            availability: CONSTANTS.AVAILABLE_NOW,
            availableFrom: now.toDate(),
          };
        }
      }
    }
  } else {
    return { availability: CONSTANTS.TEMPORARILY_UNAVAILABLE };
  }
};

export const getBranchesAvailability = (state, props) => {
  const {
    pageContext: { branches },
  } = props;
  const {
    locations: { branchesAvailability },
  } = state;

  if (_.isEmpty(branchesAvailability)) {
    return {};
  }

  const relevantBranchesAvailability = getBranchesAvailabilityIntersectionWithBranches(
    branches,
    branchesAvailability,
  );

  const selectedServingOption = getOrderSelectedServingOption(state, props);

  const branchesAvailableInServingOption = _.filter(
    relevantBranchesAvailability,
    (branchAvailability) =>
      !_.includes(
        selectedServingOption.unavailableInBranches,
        branchAvailability.branchId,
      ),
  );
  const branchesAvailabilityWithDetailedOpenHours = branchesAvailabilityReformatTimeFrames(
    branchesAvailableInServingOption,
  );
  const deliveryOptions = deliveryOptionsFilteredByAvailability(state, props);

  const {
    firstAvailableDates,
    branchesClosedDays,
    futureOrderIntervals,
  } = getFutureOrderAvailability({
    selectedServingOption,
    branchesAvailability: branchesAvailabilityWithDetailedOpenHours,
    deliveryOptions,
  });

  const branchesAvailabilityDecoratedWithNextAvailableTimeFrameAndAvailabilty = _.reduce(
    branchesAvailabilityWithDetailedOpenHours,
    (acc, branchAvailability) => {
      const branchId = branchAvailability.branchId;
      const firstAvailableDate = firstAvailableDates[branchId];
      const branchClosedDays = branchesClosedDays[branchId];

      const availability = getBranchAvailability({
        branchAvailability,
        firstAvailableDate,
        deliveryOptions,
        selectedServingOption,
        branchClosedDays,
      });

      acc[branchId] = {
        ...branchesAvailabilityWithDetailedOpenHours[branchId],
        ...availability,
        branchClosedDays,
        futureOrderInterval: futureOrderIntervals[branchId],
      };

      return acc;
    },
    {},
  );
  return branchesAvailabilityDecoratedWithNextAvailableTimeFrameAndAvailabilty;
};

export const deliveryOptionsFilteredByAvailability = (state, props) => {
  const {
    locations: { deliveryOptions },
  } = state;
  const {
    pageContext: { branches },
  } = props;

  const deliveryOptionsFilteredByBranches = _.filter(
    deliveryOptions,
    (deliveryOption) => _.find(branches, { id: deliveryOption.branchId }),
  );

  const selectedServingOption = getOrderSelectedServingOption(state, props);
  const deliveryOptionsFilteredByServingOption = _.filter(
    deliveryOptionsFilteredByBranches,
    { servingOptionId: selectedServingOption.id },
  );

  if (
    !selectedServingOption ||
    _.isEmpty(selectedServingOption.unavailableInBranches)
  ) {
    return deliveryOptionsFilteredByServingOption;
  }

  return _.filter(
    deliveryOptionsFilteredByServingOption,
    (deliveryOption) =>
      !_.includes(
        selectedServingOption.unavailableInBranches,
        deliveryOption.branch.id,
      ),
  );
};

export const hasChargeCard = (state, props) => {
  const hasCashback = _.get(
    props.pageContext,
    "businessAppConfiguration.hasCashback",
  );
  const prePaymentEnabled = _.get(
    props.pageContext,
    "businessAppConfiguration.prePaymentEnabled",
  );
  return prePaymentEnabled || hasCashback;
};

export const canUserLoadChargeCard = (state, props) => {
  const loadCardEnabled = _.get(
    props.pageContext,
    "businessAppConfiguration.loadCardEnabled",
  );
  const {
    user: { loggedIn },
  } = state;
  return loggedIn && loadCardEnabled;
};

export const hasChargeCardDiscount = (state, props) => {
  const { user } = state;
  const chargeCardBalance =
    _.get(user, "loyaltyProfile.data.openChargeCardInstance.loadedAmount", 0) -
    _.get(user, "loyaltyProfile.data.openChargeCardInstance.usedAmount", 0);
  return chargeCardBalance > 0;
};

export const getLoadChargeCardBenefitInPercentage = (state, props) => {
  const {
    pageContext: { business },
  } = props;
  const loadCardEnabled = canUserLoadChargeCard(state, props);
  if (!loadCardEnabled) return null;

  const openChargeCard = _.get(business, "openChargeCard");
  if (!openChargeCard) {
    return null;
  }

  const { price, cardAmount } = openChargeCard;
  return _.round(1 - price.amount / cardAmount.amount, 2);
};

export const getRechargeCardBenefitPrices = (state, props) => {
  const {
    pageContext: { business },
  } = props;
  const openChargeCard = _.get(business, "openChargeCard");
  if (!openChargeCard) {
    return null;
  }

  const { cardAmount, price } = openChargeCard;
  return {
    benefit: cardAmount.amount - price.amount,
    amountToCharge: price.amount,
  };
};

export const userHasEnoughLoadedOnCardForOrder = (state, props) => {
  const { order } = state;
  const priceDetails = getPriceDetailsForCheckout(state, props);

  return priceDetails.total < 0.01;
};

export const predictedOrderPrice = (state, props) => {
  const { order } = state;
  const firstBatchPriceOrBasePrice = _.get(
    order,
    "checkoutResponse.couponBatchesResponse.batches[0].priceAfterDiscount",
    _.get(order, "checkoutResponse.finalPrice", 0),
  );

  return firstBatchPriceOrBasePrice;
};

export const shouldHideWarningMessageForServingOption = (state, props) => {
  const currentServingOption = getOrderSelectedServingOption(state, props);
  if (!currentServingOption) {
    return true;
  }
  return false;
};

export const getAvailableServingOptions = (state, props) => {
  const {
    pageContext: { servingOptions, businessAppConfiguration ,
    business: {
      appStyles: {
        skipServingOptionsPage
      }
    }
  },
  } = props;
  const deliveryDisabled = businessAppConfiguration.deliveryDisabled;

  const servingOptionsInOrder = skipServingOptionsPage ? _.map(CONSTANTS.SERVING_OPTION_ORDER, (type) =>
    _.find(servingOptions, (option) => type === option.type)
  ) : servingOptions;

  return _.filter(servingOptionsInOrder, (option) => {
    if (!option) return false;

    const { type, availableOnlyFromDeepLink } = option;
    return (
      (deliveryDisabled ? type !== "delivery" : true) &&
      !availableOnlyFromDeepLink
    );
  });
};

export const getLatestOrders = (state, props) => {
  const {
    pageContext: { menuData },
  } = props;
  const { user } = state;

  const itemsById = _.keyBy(_.flatMap(menuData, "items"), "id");

  const latestOrderWithMenuData = _.map(
    _.filter(user.latestOrders, "servingOption"),
    (order) => {
      return {
        ...order,
        orderItems: _.map(order.orderItems, (item) => ({
          ...item,
          sourceItem:
            itemsById[_.get(item, "configuredMenuItemOrderData.menuItemId")],
        })),
      };
    },
  );
  return latestOrderWithMenuData;
};

export const getRelevantSubscriptionsForUser = (state, props) => {
  const {
    pageContext: {
      subscriptionPolicies
    }
  } = props;
  const { user } = state;
  const userGroupIds = _.get(user, "loyaltyProfile.data.groupIds");

  return _.filter(subscriptionPolicies, subscriptionPolicy => {
    return (_.isEmpty(_.get(subscriptionPolicy, "groupIds")) || 
          !_.isEmpty(_.intersection(userGroupIds, _.get(subscriptionPolicy, "groupIds"))) && 
    _.isEmpty(_.intersection(userGroupIds, _.get(subscriptionPolicy, "notGroupIds"))));
  });
}

const getPosItemToMenuItem = (menuData, currentBranch, selectId) => {

  
  return _.reduce(
    _.filter(_.flatMap(menuData, "items"), i => {
      return _.get(i, "servingOptions") &&
        !_.includes(_.get(i, "unavailableInBranches"), _.get(currentBranch, "id"));
    }),
    (acc, item) => {      
      const posItemIdsToItem = _.fromPairs(
        _.map(
          _.uniq(
            _.concat(_.map(_.flatMap(item.servingOptions, "sizeOptions"), "posItemId"),
              item.sizeOptionsPosItemIds
            )
          ),
          (posItemId) => [posItemId, selectId ? [item.id] : item]
        )
      );
      return {
        ...acc,
        ...posItemIdsToItem,
      };
    },
    {}
  );
}

