import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getParams, navigateTo, setParams } from "../../utils/location";
import withTranslation from "../../hocs/withTranslation";
import SignupView from "../SignupView";
import { SignupViewWithRecaptcha } from "../SignupView";
import LoginView from "../LoginView";
import VerifyCodeView from "../VerifyCodeView";
import ConsentView from "../ConsentView";
import Modal from "../Modal";
import { LOGIN_TYPES } from "../LoginView";
import {
  openAuthLogin,
  openAuthSignup,
  showVerifyCode,
  closeVerifyCode,
  loadPrivacyConsentDetails,
  showPrivacyConsent,
  showPrivacyConsentInfoModal,
  closePrivacyConsent,
  setProfileInSignupFlowMode
} from "../../store/user/actions";
import { closeAuthModal, closeAuthView } from "../../store/app/actions";
import { getStyles } from "../../store/selectors";
import { isNewUser } from "../../utils/user";

import "slick-carousel/slick/slick.css";

const LOGIN_SIGNUP_SLIDE = 0;
const VERIFY_CODE_SLIDE = 1;

function AuthView({ T, modalMode, ...props }) {
  const dispatch = useDispatch();
  const {
    app: { isSSR, authModalOpen, inStoreSignupBranchId, loginType },
    user: {
      authMode,
      showVerifyView,
      showPrivacyConsent: shouldShowPrivacyConsent,
      loggedIn,
      loyaltyProfile,
      consentState: { sent: consentSent },
      showPrivacyConsentInfoModal: shouldShowPrivacyConsentInfoModal,
      isSingUpInProgress
    },
  } = useSelector(({ app, user }) => ({ app, user }));
  const app = useSelector(({ app }) => app);

  const [disableSignupInputs, setDisableSignupInputs] = useState(false);

  const { pageContext, location } = props;

  const sliderRef = useRef(null);

  const appStyles = getStyles({ app }, props);

  const loyaltyProfileData = _.get(loyaltyProfile, "data");

  useEffect(() => {
    if (sliderRef.current) {
      (showVerifyView && sliderRef.current.slickGoTo(VERIFY_CODE_SLIDE)) ||
        sliderRef.current.slickGoTo(LOGIN_SIGNUP_SLIDE);
    }

    const params = getParams(location);
    if (
      (_.includes(location.pathname, "signup") ||
        params.openSignup ||
        loginType === LOGIN_TYPES.GIFT_CARD) &&
      authMode != "signup"
    ) {
      dispatch(openAuthSignup());
    } else if (
      _.includes(location.pathname, "login") &&
      authMode != "login"
    ) {
      dispatch(openAuthLogin());
    }
  }, []);

  useEffect(() => {
    if (!sliderRef.current) return;
    showVerifyView && sliderRef.current.slickGoTo(VERIFY_CODE_SLIDE)
  }, [showVerifyView])
  
  const shouldSignPrivacyConsent =
    appStyles.gdprConsentEnabled &&
    authMode === "signup" ||
    ((!_.isEmpty(_.get(loyaltyProfileData, "privacyConsent")) &&
      _.get(loyaltyProfileData, "privacyConsent.status") !== "APPROVED"));

  useEffect(() => {
    if(loggedIn && shouldSignPrivacyConsent) {
      dispatch(showPrivacyConsent());
    }
  }, [loggedIn, shouldSignPrivacyConsent, shouldShowPrivacyConsent]);

  useEffect(() => {
    loggedIn && shouldSignPrivacyConsent && dispatch(loadPrivacyConsentDetails());
  }, [loggedIn, shouldSignPrivacyConsent]);


  const handleSignupToCodeTransition = () => {
    dispatch(showVerifyCode());
    sliderRef.current.slickGoTo(VERIFY_CODE_SLIDE);
  }

  const handleVerifyCodeNextTransition = (shouldStopSignUp) => {
    const { user, appStyles, isInStoreSignup } = props;

    if (isInStoreSignup) {
      navigateTo(
        setParams("/store-thanks", {
          backPath: _.get(props, "location.pathname"),
        })
      );
    } else {
      if (_.get(appStyles, "showProfileOnSignup") && isNewUser(user)) {
        setProfileInSignupFlowMode();
        navigateTo("/profile");
      } else {
        if (
          props.loginType &&
          props.loginType !== LOGIN_TYPES.PAYMENT
        ) {
          navigateTo(`/${props.loginType}`);
        }
      }
    }

    shouldSignPrivacyConsent ? dispatch(closeAuthModal(shouldStopSignUp)) : handleCloseAuthView(shouldStopSignUp);
  };

  const handleCloseAuthView = (shouldStopSignUp) => {
    if (
      !shouldShowPrivacyConsentInfoModal &&
      loginType === LOGIN_TYPES.PAYMENT &&
      shouldShowPrivacyConsent &&
      !consentSent
    ) {
      dispatch(showPrivacyConsentInfoModal(true));
      return;
    }

    !shouldSignPrivacyConsent && dispatch(closeAuthModal(shouldStopSignUp));
    dispatch(closeAuthView(shouldStopSignUp));
    (_.includes(location.pathname, "signup") ||
      _.includes(location.pathname, "login")) &&
      navigateTo("/");
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: false,
    swipe: false,
    touchMove: false,
    lazyLoad: true,
    initialSlide: showVerifyView ? VERIFY_CODE_SLIDE : LOGIN_SIGNUP_SLIDE,
    adaptiveHeight: true,
    afterChange: (currentSlideIndex) =>
      setDisableSignupInputs(currentSlideIndex !== LOGIN_SIGNUP_SLIDE),
  };

  const isInStoreSignup = Boolean(inStoreSignupBranchId);

  const businessAppConfiguration = pageContext.businessAppConfiguration || {};
  const { useRecaptchaInSignUp } = businessAppConfiguration;
  const { recaptchaKey } = businessAppConfiguration;
  const loadWithSecurityProvider = recaptchaKey && useRecaptchaInSignUp;

  const SignupViewComponent = loadWithSecurityProvider ? (
      <SignupViewWithRecaptcha
        {...{
          T,
          pageContext,
          location,
          modalMode,
          nextTransition: handleSignupToCodeTransition,
          openAuthLogin: () => dispatch(openAuthLogin()),
          disableSignupInputs,
          handleClose: handleCloseAuthView,
          isInStoreSignup,
        }}
      />
  ) : (
    <SignupView
      {...{
        T,
        pageContext,
        location,
        modalMode,
        nextTransition: handleSignupToCodeTransition,
        openAuthLogin: () => dispatch(openAuthLogin()),
        disableSignupInputs,
        handleClose: handleCloseAuthView,
        isInStoreSignup,
      }}
    />
  );

  const authViewComponent = (
    <Slider {...settings} ref={sliderRef}>
      <div>
        {(isSSR && _.includes(location.pathname, "signup")) ||
        authMode === "signup" ||
        isInStoreSignup ? (
          SignupViewComponent
        ) : (
          <LoginView
            {...{
              T,
              pageContext,
              location,
              modalMode,
              openAuthSignup: () => dispatch(openAuthSignup()),
              showVerifyCode: () => dispatch(showVerifyCode()),
              handleClose: handleCloseAuthView,
              nextTransition: handleSignupToCodeTransition,
            }}
          />
        )}
      </div>
      {showVerifyView && (
        <VerifyCodeView
          {...{
            T,
            pageContext,
            location,
            modalMode,
            nextTransition: handleVerifyCodeNextTransition,
            closeVerifyCode: () => dispatch(closeVerifyCode()),
            handleClose: handleCloseAuthView,
            isInStoreSignup,
            inStoreSignupBranchId,
          }}
        />
      )}
    </Slider>
  );
  
  return (
    <>
      {modalMode ? (
        <Modal
          open={authModalOpen}
          onClose={() => handleCloseAuthView(true)}
          appStyles={appStyles}
          style={{ width: "100%" }}
        >
          {authViewComponent}
        </Modal>
      ) : (
        authViewComponent
      )}
      {shouldShowPrivacyConsent && (
        <ConsentView
          T={T}
          pageContext={pageContext}
          location={location}
          nextTransition={
            !isSingUpInProgress && loginType
              ? () => dispatch(closePrivacyConsent())
              : handleCloseAuthView
          }
          modalMode
        />
      )}
    </>
  );
}

export default withTranslation((props) => <AuthView {...props} />);
